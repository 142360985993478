
body {
background-color: rgb(116, 116, 116);
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;

}

.header_container {
  text-align: center;

}
.header {
  color: rgb(255, 255, 0);
  text-shadow: 3px 3px blue;
  font-size: 100px;
  background-color: rgb(180, 180, 180);
  
}
.current {
  background-color: blue;
  text-decoration: none;
}
.title {
display: flex;
flex-direction: column;
align-items: center;
padding-left: 0px;
border: 10px outset;
border-top-color:rgb(255, 255, 255);
border-left-color: rgb(255, 255, 255);
outline: 1px solid black;
text-shadow: 3px 3px salmon;

}
.card{
  width: 80vw;
  border: 7px ridge;
  outline: 1px solid black; 
  border-top-color:rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(71, 71, 71);
  border-bottom-color: rgb(71, 71, 71);
  background-color: rgb(116, 116, 116);

}
.title_card {
  border: 5px ridge;
  border-top: none;
  outline: 1px solid black; 
  border-top-color:rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(71, 71, 71);
  border-bottom-color: rgb(71, 71, 71);
  background-color: rgb(116, 116, 116);
}

img {
  border: 3px solid rgb(255, 255, 255);
  outline: 2px solid black;
  margin: 1em;
  margin-bottom: 0px;
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn {
  width: 8vw;
  height: 4vh;
  border: 4px ridge;
  border-top-color: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(71, 71, 71);
  border-bottom-color: rgb(71, 71, 71);
  outline: 1px solid black;
  background-color: rgb(116, 116, 116);
  text-shadow: 2px 2px salmon;
}

.btn:hover {
  background-color: rgb(90, 90, 90);
  border: 3px inset;
  border-top-color: rgb(71, 71, 71);
  border-left-color: rgb(71, 71, 71);
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);  
} 

.date {
  color: white;
  margin: 1em;
}

.textBlock {
  padding: 1em;
}

.gap {
  height: 1em;
}